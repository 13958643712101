import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { alertError } from "../../components/common/alertUtils";
import HeadLine from "../../components/common/HeadLine";
import TableView from "../../components/common/TableView";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import {
  FormControl,
  MenuItem,
  Button,
  TextField,
  Grid,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Skeletoned from "../../components/common/Skeletoned";
import { api } from "../../routes/config";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

type Props = {};

const ResultPage = (props: Props) => {
  const { data } = useSelector((state: RootState) => state.login);
  const customStudentId = data.item.customStudentId;
  const instituteId = data.item.instituteId;
  const [isLoading, setIsLoading] = useState(false);
  const [examList, setExamList] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState<any[]>([]);
  const [selectedYearValue, setSelectedYearValue] = useState("");
  const [selectedExamValue, setSelectedExamValue] = useState("");
  const [resultData, setResultData] = useState<any>({});

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await fetch(
          `${api}/public/exam/list?instituteId=${instituteId}`
        );
        const apiData = await response.json();
        const exam = apiData.item;
        setExamList(exam);
      } catch (error) {
        alertError("Error fetching exams");
      }
    };

    fetchExams();
  }, [instituteId]);

  useEffect(() => {
    const fetchYear = async () => {
      try {
        const yearRes = await fetch(
          `${api}/public/academic-year/list?instituteId=${instituteId}`
        );
        const yearData = await yearRes.json();
        const yearList = yearData.item;

        setSelectedYear(yearList);
      } catch (error) {
        alertError("Error fetching years");
      }
    };
    fetchYear();
  }, [instituteId]);

  const handleYearChange = (event: any) => {
    setSelectedYearValue(event.target.value);
  };

  const handleExamChange = (event: any) => {
    setSelectedExamValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (selectedExamValue && selectedYearValue) {
      try {
        setIsLoading(true);
        const resultResponse = await fetch(
          `${api}/public/exam/result-details/by/custom-student-id?customStudentId=${customStudentId}&examId=${selectedExamValue}&instituteId=${instituteId}&academicYear=${selectedYearValue}`
        );
        const results = await resultResponse.json();

        const resultData = results.item;

        if (results.item !== null && results.messageType !== 0) {
          setResultData(resultData);
        } else {
          setResultData({});
          alertError(results.message);
        }
      } catch (error) {
        setResultData({});
        alertError("Error fetching result data");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAfterCheckingValue = (value: any) => {
    console.log(value);
    if (value === -2 || value === -3) {
      return "";
    } else if (value === -1) {
      return "ABS";
    } else {
      return value;
    }
  };
  const columns = [
    {
      id: "subjectName",
      label: "Subject Name",
      showOnResponse: true,
    },
    {
      id: "fullMarks",
      label: "Full Marks",
      showOnResponse: true,
    },
    {
      id: "heightMark",
      label: "Highest Marks",
      showOnResponse: true,
    },
    {
      id: "obtainedMarks",
      label: "Obtain Marks",
      showOnResponse: true,
    },
    ...(resultData.shortCode1Visibility
      ? [
          {
            id: "shortCode1",
            label: resultData.shortCode1Name,
            showOnResponse: true,
            render: (data: any, Index: number) => {
              return getAfterCheckingValue(data.shortCode1);
            },
          },
        ]
      : []),
    ...(resultData.shortCode2Visibility
      ? [
          {
            id: "shortCode2",
            label: resultData.shortCode2Name,
            showOnResponse: true,
            render: (data: any, Index: number) => {
              return getAfterCheckingValue(data.shortCode2);
            },
          },
        ]
      : []),
    ...(resultData.shortCode3Visibility
      ? [
          {
            id: "shortCode3",
            label: resultData.shortCode3Name,
            showOnResponse: true,
            render: (data: any, Index: number) => {
              return getAfterCheckingValue(data.shortCode3);
            },
          },
        ]
      : []),
    ...(resultData.shortCode4Visibility
      ? [
          {
            id: "shortCode4",
            label: resultData.shortCode4Name,
            showOnResponse: true,
            render: (data: any, Index: number) => {
              return getAfterCheckingValue(data.shortCode4);
            },
          },
        ]
      : []),
    ...(resultData.shortCode5Visibility
      ? [
          {
            id: "shortCode5",
            label: resultData.shortCode5Name,
            showOnResponse: true,
            render: (data: any, Index: number) => {
              return getAfterCheckingValue(data.shortCode5);
            },
          },
        ]
      : []),
    ...(resultData.shortCode6Visibility
      ? [
          {
            id: "shortCode6",
            label: resultData.shortCode6Name,
            showOnResponse: true,
            render: (data: any, Index: number) => {
              return getAfterCheckingValue(data.shortCode6);
            },
          },
        ]
      : []),
    {
      id: "grade",
      label: "Grade",
      showOnResponse: true,
    },
    {
      id: "gpa",
      label: "GPA",
      showOnResponse: true,
    },
  ];

  return (
    <>
      <Paper elevation={0}>
        <HeadLine text={"Result Information"} />

        <Box sx={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
          <FormControl
            sx={{ minWidth: 200, marginRight: 10 }}
            variant="standard"
          >
            <TextField
              id="filled-select-currency"
              select
              label="Academic Year"
              variant="standard"
              value={selectedYearValue}
              onChange={handleYearChange}
            >
              {selectedYear.map((year) => (
                <MenuItem key={year.id} value={year.name}>
                  {year.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl
            sx={{ minWidth: 200, marginRight: 10 }}
            variant="standard"
          >
            <TextField
              id="filled-select-currency"
              select
              label="Exam Name"
              variant="standard"
              value={selectedExamValue}
              onChange={handleExamChange}
            >
              {examList.map((exam) => (
                <MenuItem key={exam.id} value={exam.id}>
                  {exam.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Button
            sx={{ marginTop: 2 }}
            variant="outlined"
            onClick={handleSubmit}
            startIcon={<SearchIcon />}
            color="success"
          >
            Search
          </Button>
        </Box>

        {isLoading ? (
          <Skeletoned count={15} animation="wave" />
        ) : Object.keys(resultData).length > 0 ? (
          <>
            <Box sx={{ textAlign: "center", margin: 5 }}>
              <Grid>
                <Stack direction="row">
                  <Item>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                      }}
                      subheader={
                        <ListSubheader
                          sx={{ color: "#733263" }}
                        >{`${resultData.studentName}`}</ListSubheader>
                      }
                    >
                      <ListItem>
                        <ListItemText primary="Exam Name : " />
                        <ListItemText primary={resultData.examName} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Class Position : " />
                        <ListItemText primary={resultData.classPosition} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Fail Subject :" />
                        <ListItemText
                          primary={resultData.numOfFailedSubjects}
                        />
                      </ListItem>
                    </List>
                  </Item>
                  <Item>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem>
                        <ListItemText primary="GPA :" />
                        <ListItemText primary={resultData.gpa} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Grade :" />
                        <ListItemText primary={resultData.grade} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Obtain Marks :" />
                        <ListItemText primary={resultData.obtainedMarks} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Full Marks :" />
                        <ListItemText primary={resultData.fullMarks} />
                      </ListItem>
                    </List>
                  </Item>
                </Stack>
              </Grid>
            </Box>
            {resultData.examMarks.length > 0 && (
              <TableView
                columns={columns}
                dataSource={resultData.examMarks}
                pagination={false}
              />
            )}
          </>
        ) : (
          ""
        )}
      </Paper>
    </>
  );
};

export default ResultPage;
